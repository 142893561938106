<template>
  <!-- 监控--- 设备管理 -->
  <div style="margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="设备名称：">
        <el-input v-model="queryForm.ChannelName" maxlength="30" @keyup.enter.native="onSubmit" placeholder="请输入设备名称"
          @input="(e) => (queryForm.ChannelName = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="ipv4地址：">
        <el-input v-model="queryForm.IpV4Address" @keyup.enter.native="onSubmit" placeholder="请输入ipv4地址"
          @input="(e) => (queryForm.IpV4Address = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <div class="box-card">
      <le-card title="设备管理">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('051b99aa6afc4e50a4f9753c')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="addNewItem"
              v-if='this.getUserInfo.user_name != "admin" && isShowBtn("f6739ccd8cdf4c5399070ee6")'>新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
           <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="ChannelName">
              <span
                  :style="row.ChannelName == '未配置' ? 'color: #bbb;' : 'color: #606266;'">{{row.ChannelName }}</span>
            </template>
             <template slot-scope="{row}" slot="MonitorBrand">
              <span
                  :style="row.MonitorBrand == '未配置' ? 'color: #bbb;' : 'color: #606266;'">{{row.MonitorBrand }}</span>
            </template>
             <template slot-scope="{row}" slot="MonitorModel">
              <span
                  :style="row.MonitorModel == '未配置' ? 'color: #bbb;' : 'color: #606266;'">{{row.MonitorModel }}</span>
            </template>
             <template slot-scope="{row}" slot="DeviceReceiveType">
              {{ row.DeviceReceiveType }}
            </template>
              <template slot-scope="{row}" slot="create_time">
                {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="edit(row)"
                  v-if="isShowBtn('7e4cbb90a6bc4d85b9af6ea4')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="ChannelName" label="设备名称">
              <template slot-scope="scope">
                <span
                  :style="scope.row.ChannelName == '未配置' ? 'color: #bbb;' : 'color: #606266;'">{{ scope.row.ChannelName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="MonitorBrand" label="设备品牌">
              <template slot-scope="scope">
                <span
                  :style="scope.row.MonitorBrand == '未配置' ? 'color: #bbb;' : 'color: #606266;'">{{ scope.row.MonitorBrand }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="MonitorModel" label="设备型号">
              <template slot-scope="scope">
                <span
                  :style="scope.row.MonitorModel == '未配置' ? 'color: #bbb;' : 'color: #606266;'">{{ scope.row.MonitorModel }}</span>
              </template>
            </el-table-column>
            <el-table-column label="配置方式">
              <template slot-scope="scope">
                {{ scope.row.DeviceReceiveType }}
              </template>
            </el-table-column>

            <el-table-column prop="IpV4Address" label="ipv4地址"></el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="edit(scope.row)"
                  v-if="isShowBtn('7e4cbb90a6bc4d85b9af6ea4')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增设备 -->
    <lebo-dialog :title="title" :isShow="isAddVisible" width="40%" @close="closeAddVisible" footerSlot>
      <div v-if="title == '编辑设备' && addForm.DeviceReceiveType == 'GB28181'">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="130px" class="demo-ruleForm">
          <el-form-item label="设备名称：" prop="ChannelName">
            <el-input v-model.trim="addForm.ChannelName" maxlength="30" placeholder="请输入设备名称"
              @input="(e) => (addForm.ChannelName = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="设备品牌：" prop="MonitorBrand">
            <el-select popper-class="my-select" v-model="addForm.MonitorBrand" placeholder="请选择设备品牌">
              <el-option label="海康威视" value="海康威视"></el-option>
              <el-option label="大华" value="大华"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号：" prop="MonitorModel">
            <el-select popper-class="my-select" v-model="addForm.MonitorModel" placeholder="请选择设备型号">
              <el-option label="HK789" value="HK789"></el-option>
              <el-option label="DH04897" value="DH04897"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="配置方式：" prop="DeviceReceiveType">
            <el-select popper-class="my-select" v-model="addForm.DeviceReceiveType" placeholder="请选择配置方式" disabled>
              <el-option label="NVR" :value="'NVR'"></el-option>
              <el-option label="RTSP" :value="'RTSP'"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="流媒体服务器：" prop="MediaServerId"
            :rules="[{ required: true, message: '请选择流媒体服务器', trigger: 'blur' }]" v-if="mediaServerList.length > 0">
            <el-select popper-class="my-select" v-model="addForm.MediaServerId" placeholder="请选择流媒体服务器">
              <el-option v-for="(item, index) in mediaServerList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="流媒体服务器：" v-else>
            <el-select popper-class="my-select" v-model="addForm.MediaServerId" placeholder="请选择流媒体服务器">
              <el-option v-for="(item, index) in mediaServerList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景：" prop="scene_name">
            <el-cascader popper-class="my-cascader" filterable v-model="addForm.scene_name" :options="mainFunAccCodeList"
              clearable :props="defaultParams" @change="handleChange" style="width:100%" placeholder="请选择场景">
              <template slot-scope="{ data }">
                <span>{{ data.name }}</span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="130px" class="demo-ruleForm">
          <el-form-item label="设备名称：" prop="ChannelName">
            <el-input v-model.trim="addForm.ChannelName" maxlength="30" placeholder="请输入设备名称"
              @input="(e) => (addForm.ChannelName = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="设备品牌：" prop="MonitorBrand">
            <el-select popper-class="my-select" v-model="addForm.MonitorBrand" placeholder="请选择设备品牌">
              <el-option label="海康威视" value="海康威视"></el-option>
              <el-option label="大华" value="大华"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号：" prop="MonitorModel">
            <el-select popper-class="my-select" v-model="addForm.MonitorModel" placeholder="请选择设备型号">
              <el-option label="HK789" value="HK789"></el-option>
              <el-option label="DH04897" value="DH04897"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="配置方式：" prop="DeviceReceiveType">
            <el-select popper-class="my-select" v-model="addForm.DeviceReceiveType" placeholder="请选择配置方式"
              @change="$refs.addFormRef.clearValidate(['NvrId', 'VideoSrcUrl', 'ChannelId']);">
              <el-option label="NVR" :value=" 'NVR' "></el-option>
              <el-option label="RTSP" :value=" 'RTSP' "></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="是否选择NVR：" prop="IsNVR">
          <el-switch v-model="addForm.IsNVR"></el-switch>
        </el-form-item> -->
          <div v-if=" addForm.DeviceReceiveType == 'NVR' ">
            <el-form-item label="NVR：" prop="NvrId" :rules=" [{ required: true, message: '请选择NVR名称', trigger: 'blur' }] ">
              <el-select popper-class="my-select" v-model=" addForm.NvrId " placeholder="请选择NVR">
                <el-option v-for="( item, index ) in  NVRList " :key=" index " :label=" item.nvr_name "
                  :value=" item.Id "></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通道号：" prop="ChannelId" :rules="
              [{ required: true, message: '请输入设备通道号', trigger: 'blur' },
              { pattern: /^\+?[1-9][0-9]*$/, message: '设备通道号必须数字' },]
            ">
              <el-input v-model=" addForm.ChannelId " maxlength="9" placeholder="请输入设备通道号"
                @input=" (e) => (addForm.ChannelId = validSpace(e)) "></el-input>
            </el-form-item>
          </div>
          <el-form-item label="RTSP路径：" v-if=" addForm.DeviceReceiveType == 'RTSP' " prop="VideoSrcUrl"
            :rules=" [{ required: true, message: '请输入RTSP地址', trigger: 'blur' }] ">
            <el-input v-model=" addForm.VideoSrcUrl " placeholder="请输入RTSP路径"
              @input=" (e) => (addForm.VideoSrcUrl = validSpacing(e)) "></el-input>
          </el-form-item>

          <el-form-item label="ipv4地址：" prop="IpV4Address">
            <el-input v-model=" addForm.IpV4Address " placeholder="请输入设备的ipv4地址" maxlength="32"
              @input=" (e) => (addForm.IpV4Address = validSpacing(e)) "></el-input>
          </el-form-item>
          <el-form-item label="流媒体服务器：" prop="MediaServerId"
            :rules=" [{ required: true, message: '请选择流媒体服务器', trigger: 'blur' }] ">
            <el-select popper-class="my-select" v-model=" addForm.MediaServerId " placeholder="请选择流媒体服务器">
              <el-option v-for="( item, index ) in  mediaServerList " :key=" index " :label=" item " :value=" item "></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景：" prop="scene_name">
            <el-cascader popper-class="my-cascader" filterable v-model=" addForm.scene_name " :options=" mainFunAccCodeList "
              clearable :props=" defaultParams " @change=" handleChange " style="width:100%" placeholder="请选择场景">
              <template slot-scope="{ data }">
                <span>{{ data.name }}</span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click=" isAddVisible = false ">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click=" addAndEdit " v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState, mapGetters } from 'vuex'
import { getMonitorList, getBindScene, getMediaServerList, getNVRSelctList, setMonitorSave, getMonitorAddOrEdit, setMonitorDel } from '@/api/monitoring'
export default {
  data () {
    return {
      queryForm: {
        ChannelName: '',
        IpV4Address: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      tableData: [],
      multipleSelection: [],
      // 是否展示新增对话框
      isAddVisible: false,
      title: '',
      // 新增表单
      addForm: {
        Id: '', // id,有则新增，无则修改
        ChannelName: '', // 设备名称
        MonitorBrand: '', // 设备品牌
        MonitorModel: '', // 设备型号
        ChannelId: '', // 设备通道号
        scene_name: [], // 场景名称
        DeviceReceiveType: 'RTSP', // 设备对接类型 ：枚举类型 （GB28181、RTSP、NVR）
        NvrId: '', // NVR id
        VideoSrcUrl: '', // RTSP路径
        IpV4Address: '', // 设备的ipv4地址
        MediaServerId: '' // 选中得流媒体服务器id
      },
      // NVR列表
      NVRList: [],
      // 流媒体服务器列表
      mediaServerList: [],
      // 场景列表
      mainFunAccCodeList: [],
      mediaServerId: '',
      defaultParams: {
        label: 'name',
        value: 'Id',
        children: 'children'
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '设备名称',
            prop: 'ChannelName',
            slot: true
          }, {
            label: '设备品牌',
            prop: 'MonitorBrand',
            slot: true
          }, {
            label: '设备型号',
            prop: 'MonitorModel',
            slot: true
          }, {
            label: '配置方式',
            prop: 'DeviceReceiveType',
            slot: true
          }, {
            label: 'ipv4地址',
            prop: 'IpV4Address'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      addFormRules: {
        ChannelName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        MonitorBrand: [
          { required: true, message: '请选择设备品牌', trigger: 'blur' }
        ],
        MonitorModel: [
          { required: true, message: '请选择设备型号', trigger: 'blur' }
        ],
        scene_name: [
          { type: 'array', required: true, message: '请选择场景', trigger: 'blur' }
        ],
        DeviceReceiveType: [
          { required: true, message: '请选择配置方式', trigger: 'blur' }
        ],
        IpV4Address: [
          { required: true, message: '请输入设备的ipv4地址', trigger: 'blur' },
          { pattern: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/, message: '请输入正确的ipv4地址', trigger: 'blur' }
        ],
        MediaServerId: [
          { required: true, message: '请选择流媒体服务器', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  created () {
    this.fnGetMonitorList()
  },
  computed: {
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  mounted () { },
  methods: {
    // 获取设备列表数据
    async fnGetMonitorList () {
      const res = await getMonitorList(this.queryForm)
      console.log('获取设备列表数据----', res)
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      // console.log(this.queryForm);
      this.queryForm.PageIndex = 1
      this.fnGetMonitorList()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetMonitorList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetMonitorList()
    // },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fnGetMonitorList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetMonitorList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      //  // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('是否确认删除此设备，删除后此设备下的数据也会删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var that = this
          var obj = {}
          var arr = []
          for (var i = 0; i < that.multipleSelection.length; i++) {
            arr.push(that.multipleSelection[i].MainId)
            if (arr.length === that.multipleSelection.length) {
              obj.MainId = arr.join(',')
              that.delItem(obj)
              return
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除！')
        })
      } else {
        this.$msg.warning('请选择要删除的数据')
      }
    },
    async delItem (obj) {
      const res = await setMonitorDel(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetMonitorList()
    },
    // 新增 对话框
    addNewItem () {
      this.isAddVisible = true
      this.title = '新增设备'
      var that = this
      axios.all([this.fnGetBindSceneTree(), this.fnGetNVRSelct(), this.fnGetMediaServerList()]).then(
        axios.spread(function (scene, nvr, media) {
          // console.log('获取NVR集合---', nvr.Data);
          that.NVRList = nvr.Code === 200 ? nvr.Data : []
          // console.log('获取流媒体服务器列表----', media.Data);
          that.mediaServerList = media && media.Code === 200 ? media.Data : []
          // console.log('获取场景树---', scene.Data);
          that.mainFunAccCodeList = scene.Code === 200 && scene.Data && scene.Data.length > 0 ? that.filterChildren(scene.Data) : []
        })
      )
    },
    // 获取场景树
    fnGetBindSceneTree () {
      return getBindScene()
    },
    // 获取NVR集合
    fnGetNVRSelct () {
      return getNVRSelctList()
    },
    // 获取流媒体服务器列表
    fnGetMediaServerList () {
      return getMediaServerList()
    },
    handleChange (value) {
      console.log(value)
    },
    // 当NVR选择项发生改变时
    // changeSelect(val) {
    //   // for (var i = 0; i < this.NVRList.length; i++) {
    //   //   // console.log(this.NVRList[i]._id)
    //   //   if (this.NVRList[i]._id === val) {
    //   //     this.addForm.nvr_name = this.NVRList[i].nvr_name
    //   //     return
    //   //   }
    //   // }
    // },
    // 编辑
    edit (item) {
      // console.log(item);
      this.isAddVisible = true
      this.title = '编辑设备'
      var that = this
      axios.all([this.fnGetBindSceneTree(), this.fnGetNVRSelct(), this.fnGetMediaServerList()]).then(
        axios.spread(function (scene, nvr, media) {
          console.log('获取场景树---', scene)
          // console.log('获取NVR集合---', nvr)
          // console.log('获取流媒体服务器列表----', media)
          that.mainFunAccCodeList = scene.Code === 200 && scene.Data && scene.Data.length > 0 ? that.filterChildren(scene.Data) : []
          that.NVRList = nvr.Code === 200 ? nvr.Data : []
          that.mediaServerList = media ? (media.Code === 200 ? media.Data : []) : []
        })
      )
      this.getMonitorEdit(item.MainId)
    },
    // 获取编辑列表
    async getMonitorEdit (id) {
      this.addForm.scene_name = []
      var res = await getMonitorAddOrEdit({
        MainId: id
      })
      console.log('获取编辑列表----', res.Data)
      if (res && res.Code === 200) {
        this.addForm.Id = res.Data.Id
        this.addForm.ChannelName = res.Data.ChannelName
        this.addForm.MonitorBrand = res.Data.MonitorBrand === '未配置' ? '' : res.Data.MonitorBrand
        this.addForm.MonitorModel = res.Data.MonitorModel === '未配置' ? '' : res.Data.MonitorModel
        this.addForm.SceneId = res.Data.SceneId
        this.addForm.CategoryId = res.Data.CategoryId
        this.addForm.DeviceReceiveType = res.Data.DeviceReceiveType
        if (res.Data.DeviceReceiveType === 'RTSP') {
          this.addForm.VideoSrcUrl = res.Data.VideoSrcUrl
        } else if (res.Data.DeviceReceiveType === 'NVR') {
          this.addForm.NvrId = res.Data.NvrId
          this.addForm.ChannelId = res.Data.ChannelId
        }
        this.addForm.IpV4Address = res.Data.IpV4Address
        if (res.Data.MediaServerId.indexOf('unknown_server_') !== -1) {
          var that = this
          setTimeout(() => {
            if (that.mediaServerList.length > 0) {
              that.addForm.MediaServerId = that.mediaServerList[0]
            } else {
              that.mediaServerId = res.Data.MediaServerId
            }
          }, 200)
        } else {
          this.addForm.MediaServerId = res.Data.MediaServerId
        }
        this.addForm.scene_name = res.Data.SecneCategoryData ? res.Data.SecneCategoryData.split(',') : []
      }
    },
    // 确定新增或编辑
    addAndEdit () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          this.fnSetObj()
        } else {
          return false
        }
      })
    },
    fnSetObj () {
      var obj = {}
      obj.Id = this.addForm.Id ? this.addForm.Id : ''
      obj.ChannelName = this.addForm.ChannelName
      obj.MonitorBrand = this.addForm.MonitorBrand
      obj.MonitorModel = this.addForm.MonitorModel
      obj.SecneCategoryData = this.addForm.scene_name.join(',') // 场景名称
      obj.SceneId = this.addForm.scene_name[0]
      obj.CategoryId = this.addForm.scene_name.length > 0 ? this.addForm.scene_name[this.addForm.scene_name.length - 1] : ''
      obj.DeviceReceiveType = this.addForm.DeviceReceiveType
      if (this.addForm.DeviceReceiveType === 'GB28181') {
      } else if (this.addForm.DeviceReceiveType === 'RTSP') {
        obj.VideoSrcUrl = this.addForm.VideoSrcUrl
      } else if (this.addForm.DeviceReceiveType === 'NVR') {
        obj.ChannelId = this.addForm.ChannelId
        obj.NvrId = this.addForm.NvrId
      }
      obj.IpV4Address = this.addForm.IpV4Address
      obj.MediaServerId = this.addForm.DeviceReceiveType === 'GB28181' && this.mediaServerList.length === 0 ? this.mediaServerId : this.addForm.MediaServerId
      this.fnsetMonitorSave(obj)
    },
    async fnsetMonitorSave (obj) {
      const res = await setMonitorSave(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.isAddVisible = false
      if (obj.Id) {
        this.fnGetMonitorList()
      } else {
        this.onSubmit()
      }
    },
    // 关闭对话框重置数据
    closeAddVisible () {
      this.isAddVisible = false
      // console.log('closeAddVisible-----');
      this.$refs.addFormRef.clearValidate()
      this.addForm.Id = ''
      this.addForm.ChannelName = ''
      this.addForm.MonitorBrand = ''
      this.addForm.MonitorModel = ''
      this.addForm.ChannelId = ''
      this.addForm.scene_name = []
      this.addForm.SceneId = ''
      this.addForm.CategoryId = ''
      this.addForm.DeviceReceiveType = 'RTSP'
      this.addForm.NvrId = ''
      this.addForm.VideoSrcUrl = ''
      this.addForm.IpV4Address = ''
      this.addForm.MediaServerId = ''
      // console.log(this.addForm)
    }
  }
}
</script>

<style scoped lang="less">.el-select {
  width: 100%;
}</style>
